import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Video from '../components/video';
import SEO from "../components/seo";
import PageLayout from '../components/page-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title={"Build"} mdxType="SEO" />
    <h1>{`Build`}</h1>
    <h2>{`Robot's body`}</h2>
    <p>{`The only non-3d-printed parts of the robot are 3mm acrylic plate and 10mm M3 bolts and nuts.  `}</p>
    <h3>{`Acrylic plate base`}</h3>
    <p>{`Acrylic was chosen because of its strength and ease of cutting/drilling with Dremel.
`}<a parentName="p" {...{
        "href": "https://github.com/Leonti/avobot-body/blob/master/acrylic_plate.pdf"
      }}>{`Here`}</a>{` is the pdf which can be printed and used for cutting.`}<br parentName="p"></br>{`
`}{`You don't have to worry if the edges are not straight or round enough since they will be hidden behind a 3d-printed shell and would not be visible later.  `}</p>
    <p>{`There is also an STL of the plate in case you have a printer big enough to print it (it's 208mm in diameter).`}<br parentName="p"></br>{`
`}{`This could save you some time.  `}</p>
    <h3>{`3D-printed parts assembly`}</h3>
    <p>{`All STLs are available `}<a parentName="p" {...{
        "href": "./stls"
      }}>{`here`}</a><br parentName="p"></br>{`
`}{`They were designed to fit a 15x15cm build plate, so they can be printed on almost any printer.  `}</p>
    <h4>{`Motors stand`}</h4>
    <p>{`Stand for motors also supports the top cover plates.  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/36ed1ec394cd8b212361be0581050906/d2602/assembly.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABzuRNIVh//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECAxESIv/aAAgBAQABBQLmNmywpalaJ4iLo//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAECERAhIjEy/9oACAEBAAY/ArRAujlqmdJP/8QAGxABAAEFAQAAAAAAAAAAAAAAAQARITFRYUH/2gAIAQEAAT8huUAY5F72kIUehmcFvEg2QIoWjP/aAAwDAQACAAMAAAAQJy//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QBtX/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QtYf/xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMVFxsf/aAAgBAQABPxDA2l0aRi5u7AHcAovLNM2IWd3rf2IqNC0HfUGvntDPD9OZP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Assembly",
            "title": "Assembly",
            "src": "/static/36ed1ec394cd8b212361be0581050906/e5166/assembly.jpg",
            "srcSet": ["/static/36ed1ec394cd8b212361be0581050906/f93b5/assembly.jpg 300w", "/static/36ed1ec394cd8b212361be0581050906/b4294/assembly.jpg 600w", "/static/36ed1ec394cd8b212361be0581050906/e5166/assembly.jpg 1200w", "/static/36ed1ec394cd8b212361be0581050906/d9c39/assembly.jpg 1800w", "/static/36ed1ec394cd8b212361be0581050906/df51d/assembly.jpg 2400w", "/static/36ed1ec394cd8b212361be0581050906/d2602/assembly.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use file to make sure the holes are a tight fit for the motors.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a1a3076761d84e3b6d924b68acf5b5ed/d2602/motor_mount.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAeajZVEhX//EABoQAAIDAQEAAAAAAAAAAAAAAAECABETAxL/2gAIAQEAAQUCyatIXdJoWi17JHSf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRITFBUf/aAAgBAQAGPwJSdkYp5QljRXg3Re+H/8QAGhAAAgMBAQAAAAAAAAAAAAAAASEAETFBYf/aAAgBAQABPyFgJ0yxCNSyVSEDewst4l4Y3RpTwDU//9oADAMBAAIAAwAAABAL3//EABYRAQEBAAAAAAAAAAAAAAAAAAEAQf/aAAgBAwEBPxAch3//xAAWEQEBAQAAAAAAAAAAAAAAAAABAEH/2gAIAQIBAT8QTZN//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIUGRMWH/2gAIAQEAAT8QAdjCoruEdWo6VXv6ztZC1V19lJmg8B5jDVbJlKuJAtGy+2T/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Motor mount",
            "title": "Motor mount",
            "src": "/static/a1a3076761d84e3b6d924b68acf5b5ed/e5166/motor_mount.jpg",
            "srcSet": ["/static/a1a3076761d84e3b6d924b68acf5b5ed/f93b5/motor_mount.jpg 300w", "/static/a1a3076761d84e3b6d924b68acf5b5ed/b4294/motor_mount.jpg 600w", "/static/a1a3076761d84e3b6d924b68acf5b5ed/e5166/motor_mount.jpg 1200w", "/static/a1a3076761d84e3b6d924b68acf5b5ed/d9c39/motor_mount.jpg 1800w", "/static/a1a3076761d84e3b6d924b68acf5b5ed/df51d/motor_mount.jpg 2400w", "/static/a1a3076761d84e3b6d924b68acf5b5ed/d2602/motor_mount.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is how it looks with wheels on and a bumper attached and assembled.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b1887a3923004455e2a7eb228c9d291d/d2602/assembly_with_wheels.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABtweKOoMV/8QAGxAAAwACAwAAAAAAAAAAAAAAAQIDABASEzH/2gAIAQEAAQUCZ1TBTjq8uwLI0Pmf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQQDAQAAAAAAAAAAAAAAAQAQERICIjFB/9oACAEBAAY/Atj1G+bAjoUVget//8QAGRABAQEBAQEAAAAAAAAAAAAAAREAMSFx/9oACAEBAAE/IZRF4xUOc5gEo+O+APVAF1dAAcN//9oADAMBAAIAAwAAABCMD//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EAn/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBZ/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAIUFRMf/aAAgBAQABPxBEiyXvWOliDh9YYkBCj6OcQKKFlH6XW8ju4KUAA8N//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Assembly with wheels",
            "title": "Assembly with wheels",
            "src": "/static/b1887a3923004455e2a7eb228c9d291d/e5166/assembly_with_wheels.jpg",
            "srcSet": ["/static/b1887a3923004455e2a7eb228c9d291d/f93b5/assembly_with_wheels.jpg 300w", "/static/b1887a3923004455e2a7eb228c9d291d/b4294/assembly_with_wheels.jpg 600w", "/static/b1887a3923004455e2a7eb228c9d291d/e5166/assembly_with_wheels.jpg 1200w", "/static/b1887a3923004455e2a7eb228c9d291d/d9c39/assembly_with_wheels.jpg 1800w", "/static/b1887a3923004455e2a7eb228c9d291d/df51d/assembly_with_wheels.jpg 2400w", "/static/b1887a3923004455e2a7eb228c9d291d/d2602/assembly_with_wheels.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`View from the bottom
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c5d40425074a4283da44ebefba9d6ede/d2602/assembly_from_the_bottom.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFZ0wZiEf/EABkQAQEAAwEAAAAAAAAAAAAAAAECAAMRE//aAAgBAQABBQK74r2SBHUzkjePrD//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhAhIxUcH/2gAIAQEABj8CeHTLJRvT8IaIXB//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxIUH/2gAIAQEAAT8hFQLQdbHSYRwTW2I9zxTO3HxT8b//2gAMAwEAAgADAAAAEIs//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EMEtL//EABcRAQADAAAAAAAAAAAAAAAAAAABEUH/2gAIAQIBAT8Q1Uv/xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMYFBYXHB8f/aAAgBAQABPxDGYw8YcPctIWifEHx0EWp/Q7b7IFQrRd/PUc0bWVx3P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Assembly from the bottom",
            "title": "Assembly from the bottom",
            "src": "/static/c5d40425074a4283da44ebefba9d6ede/e5166/assembly_from_the_bottom.jpg",
            "srcSet": ["/static/c5d40425074a4283da44ebefba9d6ede/f93b5/assembly_from_the_bottom.jpg 300w", "/static/c5d40425074a4283da44ebefba9d6ede/b4294/assembly_from_the_bottom.jpg 600w", "/static/c5d40425074a4283da44ebefba9d6ede/e5166/assembly_from_the_bottom.jpg 1200w", "/static/c5d40425074a4283da44ebefba9d6ede/d9c39/assembly_from_the_bottom.jpg 1800w", "/static/c5d40425074a4283da44ebefba9d6ede/df51d/assembly_from_the_bottom.jpg 2400w", "/static/c5d40425074a4283da44ebefba9d6ede/d2602/assembly_from_the_bottom.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Electronics and wiring all in place
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d70e4132a72e7624078aa2cbc0fd4cc2/d2602/assembly_with_electronics.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAegFwh9lH//EABsQAQEAAQUAAAAAAAAAAAAAAAECAwAEERIU/9oACAEBAAEFAqsxhuKnRXYuDJPnVOJP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAiEiQf/aAAgBAQAGPwKsuW0Jr0h3IRH/xAAbEAACAwADAAAAAAAAAAAAAAAAARExcSFBUf/aAAgBAQABPyGascwhpqpKhVaSSctT8ZaseyCqVH//2gAMAwEAAgADAAAAEAcP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QJ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhQVFhMXH/2gAIAQEAAT8QOzGDt4MqYogA9fXL1aC5qGIiiw5yaxAps/jWj5jG0ODzP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Assembly with electronics",
            "title": "Assembly with electronics",
            "src": "/static/d70e4132a72e7624078aa2cbc0fd4cc2/e5166/assembly_with_electronics.jpg",
            "srcSet": ["/static/d70e4132a72e7624078aa2cbc0fd4cc2/f93b5/assembly_with_electronics.jpg 300w", "/static/d70e4132a72e7624078aa2cbc0fd4cc2/b4294/assembly_with_electronics.jpg 600w", "/static/d70e4132a72e7624078aa2cbc0fd4cc2/e5166/assembly_with_electronics.jpg 1200w", "/static/d70e4132a72e7624078aa2cbc0fd4cc2/d9c39/assembly_with_electronics.jpg 1800w", "/static/d70e4132a72e7624078aa2cbc0fd4cc2/df51d/assembly_with_electronics.jpg 2400w", "/static/d70e4132a72e7624078aa2cbc0fd4cc2/d2602/assembly_with_electronics.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`DIY power strip with 3 rows of dupont connectors - ground, 5v and 3.3v.`}<br parentName="p"></br>{`
`}{`It's very handy because you don't have to think about calculating a number of power wires you will need upfront.
It will also allow to add more devices in the future.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/03f4b4e200ea0f0655a275c37de52d8d/d2602/power_strip.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHOoucbBBn/xAAaEAADAQADAAAAAAAAAAAAAAAAAQIDBBEx/9oACAEBAAEFApoiheLj9uc5zEz/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEhAhAREkH/2gAIAQEABj8CzUR0Q9kQrf/EABoQAQACAwEAAAAAAAAAAAAAAAEAIRExUZH/2gAIAQEAAT8hyZtNRGjUHdyCUw7KP2i4n//aAAwDAQACAAMAAAAQLD//xAAWEQADAAAAAAAAAAAAAAAAAAAQETH/2gAIAQMBAT8QhD//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBcaGx0f/aAAgBAQABPxBGJAHuWGvnrHCKzprbreRLkC3xjPSHIUb1+52iCVVxhuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Power strip",
            "title": "Power strip",
            "src": "/static/03f4b4e200ea0f0655a275c37de52d8d/e5166/power_strip.jpg",
            "srcSet": ["/static/03f4b4e200ea0f0655a275c37de52d8d/f93b5/power_strip.jpg 300w", "/static/03f4b4e200ea0f0655a275c37de52d8d/b4294/power_strip.jpg 600w", "/static/03f4b4e200ea0f0655a275c37de52d8d/e5166/power_strip.jpg 1200w", "/static/03f4b4e200ea0f0655a275c37de52d8d/d9c39/power_strip.jpg 1800w", "/static/03f4b4e200ea0f0655a275c37de52d8d/df51d/power_strip.jpg 2400w", "/static/03f4b4e200ea0f0655a275c37de52d8d/d2602/power_strip.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Lidar is mounted on a sort of a "sleeve" which then slides onto the battery.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f673bfb8b169ee8244bd527109997d5f/d2602/lidar_stand.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFxR+bFQyV//8QAGhAAAwEAAwAAAAAAAAAAAAAAAQIDABITMf/aAAgBAQABBQK7hEAmz+ai8t1OKEA7/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERIRAxcf/aAAgBAQAGPwKxbnuEJzKVln//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMUFh/9oACAEBAAE/IW/LvEJ9JGV3oqw8Pek+HSqoaVKf/9oADAMBAAIAAwAAABCcD//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMVFxgaH/2gAIAQEAAT8QDgmqZK5Q0F5+5OGo95HtVxhL3HCK9saeesLIQTP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lidar stand",
            "title": "Lidar stand",
            "src": "/static/f673bfb8b169ee8244bd527109997d5f/e5166/lidar_stand.jpg",
            "srcSet": ["/static/f673bfb8b169ee8244bd527109997d5f/f93b5/lidar_stand.jpg 300w", "/static/f673bfb8b169ee8244bd527109997d5f/b4294/lidar_stand.jpg 600w", "/static/f673bfb8b169ee8244bd527109997d5f/e5166/lidar_stand.jpg 1200w", "/static/f673bfb8b169ee8244bd527109997d5f/d9c39/lidar_stand.jpg 1800w", "/static/f673bfb8b169ee8244bd527109997d5f/df51d/lidar_stand.jpg 2400w", "/static/f673bfb8b169ee8244bd527109997d5f/d2602/lidar_stand.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With lidar and battery
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a90ea658cb124e71e233a6d8b75483d7/d2602/assembly_with_lidar.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAASFCHbimf//EABsQAAMAAgMAAAAAAAAAAAAAAAECAxIhAAQR/9oACAEBAAEFAu3tkaicB9FpYUxLMND/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABAwUAAAAAAAAAAAAAAAABABAREiExQlH/2gAIAQEABj8CyhSbcLTqVDf/xAAZEAEAAwEBAAAAAAAAAAAAAAABABExQSH/2gAIAQEAAT8hOeDwgSSnh0iAu9niXpUBw3WW5GmLb1n/2gAMAwEAAgADAAAAEBj/AP/EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QDLj/xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EF2o/8QAGxABAQADAQEBAAAAAAAAAAAAAREAIUExUWH/2gAIAQEAAT8QBYqQGgO1zW0iIQrz5cIkGaHHuVh1i+3Y/mMW2R4ckPjwlfuf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Assembly with lidar",
            "title": "Assembly with lidar",
            "src": "/static/a90ea658cb124e71e233a6d8b75483d7/e5166/assembly_with_lidar.jpg",
            "srcSet": ["/static/a90ea658cb124e71e233a6d8b75483d7/f93b5/assembly_with_lidar.jpg 300w", "/static/a90ea658cb124e71e233a6d8b75483d7/b4294/assembly_with_lidar.jpg 600w", "/static/a90ea658cb124e71e233a6d8b75483d7/e5166/assembly_with_lidar.jpg 1200w", "/static/a90ea658cb124e71e233a6d8b75483d7/d9c39/assembly_with_lidar.jpg 1800w", "/static/a90ea658cb124e71e233a6d8b75483d7/df51d/assembly_with_lidar.jpg 2400w", "/static/a90ea658cb124e71e233a6d8b75483d7/d2602/assembly_with_lidar.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is how the battery looks like (`}<inlineCode parentName="p">{`EasyAcc PB10000CF Power Bank`}</inlineCode>{`).
Battery stands and a lidar mount depend on battery dimensions, so if you can't find this battery you'll have to modify
3d sources or print an "adapter" if you have a smaller battery.`}<br parentName="p"></br>{`
`}{`More modular approach which would allow to use different batteries is a work in progress.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6612e11ce4d845de2ba351856ef391ab/d2602/anker_battery.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGhOllcIB//xAAbEAABBQEBAAAAAAAAAAAAAAADAAECBBIRFP/aAAgBAQABBQKRn09iSCTcHq9fxquPI//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAEFAQAAAAAAAAAAAAAAAAABETEzoSH/2gAIAQEABj8CXpI6lmFmEyf/xAAaEAADAQADAAAAAAAAAAAAAAAAARExQVGR/9oACAEBAAE/IUELLE8Twwi2D2vkxfYRa1an/9oADAMBAAIAAwAAABBf/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qyo//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Q2K//xAAgEAEAAgECBwAAAAAAAAAAAAABACERMUFRYXGBwdHw/9oACAEBAAE/EMKoQDBtEbZzMdqx0rh7hbQM4dGe8U1+vWJe4RVbeJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Battery",
            "title": "Battery",
            "src": "/static/6612e11ce4d845de2ba351856ef391ab/e5166/anker_battery.jpg",
            "srcSet": ["/static/6612e11ce4d845de2ba351856ef391ab/f93b5/anker_battery.jpg 300w", "/static/6612e11ce4d845de2ba351856ef391ab/b4294/anker_battery.jpg 600w", "/static/6612e11ce4d845de2ba351856ef391ab/e5166/anker_battery.jpg 1200w", "/static/6612e11ce4d845de2ba351856ef391ab/d9c39/anker_battery.jpg 1800w", "/static/6612e11ce4d845de2ba351856ef391ab/df51d/anker_battery.jpg 2400w", "/static/6612e11ce4d845de2ba351856ef391ab/d2602/anker_battery.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back cover assembled. Could probably be printed in a single peace on bigger printers.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0a9cae97480e22d5b60a467e8215c5b8/d2602/back_cover.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAVeFBGA//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAERQEE//aAAgBAQABBQI9CM2qxpEygTwWs0//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwGI/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABsQAAMAAgMAAAAAAAAAAAAAAAABMhESMZGh/9oACAEBAAY/As6PshkstnLL8P/EABsQAAMAAgMAAAAAAAAAAAAAAAABESExcaHh/9oACAEBAAE/IUZ7iKdiH7Qt5j4IQjeWA//aAAwDAQACAAMAAAAQRx//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/EB//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QhT//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhQWFxgeH/2gAIAQEAAT8QcuzlAU5i5lGmFnPYYCHNKAQaKHwxlNB39T//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Back cover",
            "title": "Back cover",
            "src": "/static/0a9cae97480e22d5b60a467e8215c5b8/e5166/back_cover.jpg",
            "srcSet": ["/static/0a9cae97480e22d5b60a467e8215c5b8/f93b5/back_cover.jpg 300w", "/static/0a9cae97480e22d5b60a467e8215c5b8/b4294/back_cover.jpg 600w", "/static/0a9cae97480e22d5b60a467e8215c5b8/e5166/back_cover.jpg 1200w", "/static/0a9cae97480e22d5b60a467e8215c5b8/d9c39/back_cover.jpg 1800w", "/static/0a9cae97480e22d5b60a467e8215c5b8/df51d/back_cover.jpg 2400w", "/static/0a9cae97480e22d5b60a467e8215c5b8/d2602/back_cover.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should just be able to press M3 nuts into their slots.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/54d2f3d24850712d159ed538a2946ccd/d2602/back_cover_bottom.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABhO5TFjSP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxIE/9oACAEBAAEFAnyFZCmppmI6bR//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAAYDAAAAAAAAAAAAAAAAAAEQESEiAhIx/9oACAEBAAY/Aq5A36QsUps0p//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/IStUPjsAZdFSw6PJgla5UA6E/9oADAMBAAIAAwAAABCX7//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCGv//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCxj//EABsQAQADAQADAAAAAAAAAAAAAAEAESFhMVGR/9oACAEBAAE/EPAoYOnrHdCL9OwDsLpVXIxIGQttVZRPin//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Back cover bottom",
            "title": "Back cover bottom",
            "src": "/static/54d2f3d24850712d159ed538a2946ccd/e5166/back_cover_bottom.jpg",
            "srcSet": ["/static/54d2f3d24850712d159ed538a2946ccd/f93b5/back_cover_bottom.jpg 300w", "/static/54d2f3d24850712d159ed538a2946ccd/b4294/back_cover_bottom.jpg 600w", "/static/54d2f3d24850712d159ed538a2946ccd/e5166/back_cover_bottom.jpg 1200w", "/static/54d2f3d24850712d159ed538a2946ccd/d9c39/back_cover_bottom.jpg 1800w", "/static/54d2f3d24850712d159ed538a2946ccd/df51d/back_cover_bottom.jpg 2400w", "/static/54d2f3d24850712d159ed538a2946ccd/d2602/back_cover_bottom.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back cover has a 3mm slot (actually it's slightly bigger than 3mm) for acrylic plate to slide in.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/830e0b10f95c19381d7f2abb248d99d9/d2602/back_cover_bottom_detail.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABk3ZiOiDP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAESED/9oACAEBAAEFAnbeRtgxozBLn//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAFxAAAwEAAAAAAAAAAAAAAAAAARAhAP/aAAgBAQAGPwLRwBf/xAAaEAADAQADAAAAAAAAAAAAAAAAARExEEFx/9oACAEBAAE/IUQjRYhDFIfRMR4uH//aAAwDAQACAAMAAAAQ2P8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/EMxX/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAECAQE/EN1H/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBkf/aAAgBAQABPxBAK5LAudKebDBansXQfIqV1ywIgyf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Back cover bottom detail",
            "title": "Back cover bottom detail",
            "src": "/static/830e0b10f95c19381d7f2abb248d99d9/e5166/back_cover_bottom_detail.jpg",
            "srcSet": ["/static/830e0b10f95c19381d7f2abb248d99d9/f93b5/back_cover_bottom_detail.jpg 300w", "/static/830e0b10f95c19381d7f2abb248d99d9/b4294/back_cover_bottom_detail.jpg 600w", "/static/830e0b10f95c19381d7f2abb248d99d9/e5166/back_cover_bottom_detail.jpg 1200w", "/static/830e0b10f95c19381d7f2abb248d99d9/d9c39/back_cover_bottom_detail.jpg 1800w", "/static/830e0b10f95c19381d7f2abb248d99d9/df51d/back_cover_bottom_detail.jpg 2400w", "/static/830e0b10f95c19381d7f2abb248d99d9/d2602/back_cover_bottom_detail.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Bumper assembly. Center bolt goes through acrylic plate and the bumper.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c53bc00438f3606a156b1d55a59bb770/d2602/bumper.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFbb4TOKyX/xAAbEAACAgMBAAAAAAAAAAAAAAABAgMSAAQRFP/aAAgBAQABBQKRFSS0dWr1lDg6zd8xz//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMhIhMYH/2gAIAQEABj8CooMU48LEM00zp//EABoQAAMAAwEAAAAAAAAAAAAAAAABETFRoUH/2gAIAQEAAT8hmUnZ0TpzvLQhK4O4dC1cAXqtP//aAAwDAQACAAMAAAAQo8//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFx/9oACAEDAQE/EFGaP//EABcRAQADAAAAAAAAAAAAAAAAAAABEXH/2gAIAQIBAT8Qm2H/xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMWFRcYGh/9oACAEBAAE/ELRUWEhPjzFJktKv2XS0MteyvnocxQMd6ruUsvhrP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bumper",
            "title": "Bumper",
            "src": "/static/c53bc00438f3606a156b1d55a59bb770/e5166/bumper.jpg",
            "srcSet": ["/static/c53bc00438f3606a156b1d55a59bb770/f93b5/bumper.jpg 300w", "/static/c53bc00438f3606a156b1d55a59bb770/b4294/bumper.jpg 600w", "/static/c53bc00438f3606a156b1d55a59bb770/e5166/bumper.jpg 1200w", "/static/c53bc00438f3606a156b1d55a59bb770/d9c39/bumper.jpg 1800w", "/static/c53bc00438f3606a156b1d55a59bb770/df51d/bumper.jpg 2400w", "/static/c53bc00438f3606a156b1d55a59bb770/d2602/bumper.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here is the video of how it behaves:`}</p>
    <Video videoSrcURL='https://www.youtube.com/embed/cyBhA5hzjGM' videoTitle='Bumper bahavior' mdxType="Video" />
    <p>{`Front cover can't be mounted on acrylic plate because this is where the bumper is mounted.`}<br parentName="p"></br>{`
`}{`There are 3 mounts which allow the front cover to "float" above the bumper.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a1eb8921f9ea9bc0d0adfe47836a9514/d2602/front_support.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAVs3lyWVg//EABkQAAMBAQEAAAAAAAAAAAAAAAABAgMRE//aAAgBAQABBQKs+D4VGadSqHhmecI//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Bqv/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAaEAACAwEBAAAAAAAAAAAAAAAAMQERITJB/9oACAEBAAY/Aq9MY7NgRzB//8QAHBABAAICAwEAAAAAAAAAAAAAAQARMUFRYXGR/9oACAEBAAE/IRLTwwrS2bhgcWiYKYwOPcDUfCf/2gAMAwEAAgADAAAAEEcv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAwEBPxCNRB//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8Qpr//xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMVFxYdH/2gAIAQEAAT8QykDoaPn5KYBxYaM1CWYVHkD5zBa6VTSKDnooCMD1P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Front support",
            "title": "Front support",
            "src": "/static/a1eb8921f9ea9bc0d0adfe47836a9514/e5166/front_support.jpg",
            "srcSet": ["/static/a1eb8921f9ea9bc0d0adfe47836a9514/f93b5/front_support.jpg 300w", "/static/a1eb8921f9ea9bc0d0adfe47836a9514/b4294/front_support.jpg 600w", "/static/a1eb8921f9ea9bc0d0adfe47836a9514/e5166/front_support.jpg 1200w", "/static/a1eb8921f9ea9bc0d0adfe47836a9514/d9c39/front_support.jpg 1800w", "/static/a1eb8921f9ea9bc0d0adfe47836a9514/df51d/front_support.jpg 2400w", "/static/a1eb8921f9ea9bc0d0adfe47836a9514/d2602/front_support.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Covers at a glance: back, front and a side cover.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6dde3f1d6245deb288c5b97e8c85ce23/d2602/cover.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB0FtWtMgT/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQISBBEA/9oACAEBAAEFAnvtufNrCkhrKLZzjv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAwADAQEAAAAAAAAAAAAAAAERAhIhQWH/2gAIAQEABj8Cx0RLimTW/TFp89RtOnHD/8QAGxAAAwADAQEAAAAAAAAAAAAAAAERITGBUZH/2gAIAQEAAT8h7RW3hFWMbctPiCeGMQK9Chsm+U8h/9oADAMBAAIAAwAAABAzH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBkf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EFf/xAAcEAEAAwACAwAAAAAAAAAAAAABABExIVFBYXH/2gAIAQEAAT8QZqFqzn2DWO9aEgjq/WzcAORidhCTBRILzXktrKzI9rjKGp//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cover",
            "title": "Cover",
            "src": "/static/6dde3f1d6245deb288c5b97e8c85ce23/e5166/cover.jpg",
            "srcSet": ["/static/6dde3f1d6245deb288c5b97e8c85ce23/f93b5/cover.jpg 300w", "/static/6dde3f1d6245deb288c5b97e8c85ce23/b4294/cover.jpg 600w", "/static/6dde3f1d6245deb288c5b97e8c85ce23/e5166/cover.jpg 1200w", "/static/6dde3f1d6245deb288c5b97e8c85ce23/d9c39/cover.jpg 1800w", "/static/6dde3f1d6245deb288c5b97e8c85ce23/df51d/cover.jpg 2400w", "/static/6dde3f1d6245deb288c5b97e8c85ce23/d2602/cover.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is how the robot looks like fully assembled
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b7fc4f90a5d46acc1a27251d68961914/b5c84/avobot_assembled.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABpfVhNawiJ//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMREkH/2gAIAQEAAQUCjN90WLNPKk2bNH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAQUBAAAAAAAAAAAAAAAAAUEQERJRYYH/2gAIAQEABj8C4YyqOGXb8Rs//8QAGxAAAwADAQEAAAAAAAAAAAAAAAERIUFRMZH/2gAIAQEAAT8hdlLx8LtgzmyxbRofwg8VROs//9oADAMBAAIAAwAAABBTz//EABYRAQEBAAAAAAAAAAAAAAAAACEQEf/aAAgBAwEBPxAxn//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxAVf//EABsQAQEAAwEBAQAAAAAAAAAAAAERACExQWGR/9oACAEBAAE/EKepN1we4hO00OT5gL3HdRowv7h6J3gBb1nuMU7jrM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AvoBot assembled",
            "title": "AvoBot assembled",
            "src": "/static/b7fc4f90a5d46acc1a27251d68961914/e5166/avobot_assembled.jpg",
            "srcSet": ["/static/b7fc4f90a5d46acc1a27251d68961914/f93b5/avobot_assembled.jpg 300w", "/static/b7fc4f90a5d46acc1a27251d68961914/b4294/avobot_assembled.jpg 600w", "/static/b7fc4f90a5d46acc1a27251d68961914/e5166/avobot_assembled.jpg 1200w", "/static/b7fc4f90a5d46acc1a27251d68961914/b5c84/avobot_assembled.jpg 1704w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      